.linea-style {
    display: grid;
    background: red;
    width: 245px;
    height: 2px;
    text-align: left;
    margin-top: 5px;
}

.icons {
    position: absolute;
    top: 11px;
    left: 20px;
    font-size: 22px;
    z-index: 9999;
}

.icons-area {
    position: absolute;
    top: 5px;
    left: 20px;
    font-size: 22px;
    z-index: 100;
}

.form-control-footer {
    width: 100%;
    height: 50px;
    margin-left: -20px;
    padding-left: 50px !important;
    background-color: white !important;
    border-radius: 25px 25px 25px 25px !important;
    border-color: #D9D9D9;
    border-width: 0;
}

.form-control-area-footer {
    width: 100%;
    height: 100px;
    /* position: relative; */
    margin-left: -60px;
    padding-left: 50px !important;
    background-color: white;
    border-radius: 25px 25px 25px 25px !important;
    border-color: #D9D9D9;
    border-width: 0;
    /* color: "black" !important; */
}




/* ===== Contact CSS ===== */
.ud-contact {
    padding: 50px 0px;
    position: relative;
}

@media (max-width: 767px) {
    .ud-contact {
        padding: 80px 0;
    }
}

.ud-contact::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 0;
    left: 0;
    height: 46%;
    background: #f3f4fe;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ud-contact::after {
        height: 45%;
    }
}

.ud-contact-title {
    margin-bottom: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .ud-contact-title {
        margin-bottom: 50px;
    }
}

.ud-contact-title span {
    color: var(--heading-color);
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 20px;
}

.ud-contact-title h2 {
    font-weight: 600;
    font-size: 35px;
    line-height: 41px;
}

.ud-contact-info-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /* justify-content: space-between; */
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .ud-contact-info-wrapper {
        margin-bottom: 50px;
    }
}

.ud-contact-info-wrapper .ud-single-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    width: 330px;
    margin-bottom: 30px;
}

.ud-contact-info-wrapper .ud-info-icon {
    font-size: 32px;
    color: var(--primary-color);
    margin-right: 24px;
}

.ud-contact-info-wrapper .ud-info-meta h5 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.ud-contact-info-wrapper .ud-info-meta p {
    font-size: 15px;
    line-height: 24px;
}

.ud-contact-form-wrapper {
    -webkit-box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: white;
    padding: 40px;
}

@media (max-width: 767px) {
    .ud-contact-form-wrapper {
        padding: 40px 30px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 576px) and (max-width: 767px) {
    .ud-contact-form-wrapper {
        padding: 50px 40px;
    }
}

.ud-contact-form-wrapper .ud-contact-form-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .ud-contact-form-wrapper .ud-contact-form-title {
        font-size: 24px;
    }
}

.ud-contact-form-wrapper .ud-form-group {
    margin-bottom: 25px;
}

.ud-contact-form-wrapper .ud-form-group label {
    display: block;
    font-weight: normal;
    font-size: 12px;
}

.ud-contact-form-wrapper .ud-form-group input,
.ud-contact-form-wrapper .ud-form-group textarea {
    display: block;
    width: 100%;
    padding: 15px 0;
    border: none;
    outline: none;
    border-bottom: 1px solid #f1f1f1;
    resize: none;
}

.ud-contact-form-wrapper .ud-form-group input:focus,
.ud-contact-form-wrapper .ud-form-group textarea:focus {
    border-color: var(--primary-color);
}



/* ===== Footer CSS ===== */
.ud-footer {

    /* background: -webkit-linear-gradient(top, #F3F8FF 10%, #2264AB 80%); */
    /* background: -webkit-linear-gradient(top, #F3F8FF 20%, #5ba0ea 90%); */
    mask-image: linear-gradient(to bottom, transparent, black 100%, black 40%);

    /* background: var(--heading-color); */
    /* padding-top: 100px; */
    /* padding-top: 50px; */
    /* margin-top: 30px; */
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.ud-footer .shape {
    position: absolute;
    z-index: -1;
}

.ud-footer .shape.shape-1 {
    top: 0;
    left: 0;
}

.ud-footer .shape.shape-2 {
    top: 0;
    right: 0;
}

.ud-footer .shape.shape-3 {
    bottom: 0;
    right: 0;
}

.ud-widget {
    margin-bottom: 50px;
}

.ud-widget .ud-footer-logo {
    max-width: 160px;
    display: block;
    margin-bottom: 10px;
}

.ud-widget .ud-footer-logo img {
    width: 100%;
}

.ud-widget .ud-widget-desc {
    font-size: 15px;
    line-height: 24px;
    color: #f3f4fe;
    margin-bottom: 30px;
    max-width: 260px;
}

.ud-widget .ud-widget-socials {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ud-widget .ud-widget-socials a {
    font-size: 16px;
    color: #dddddd;
    margin-right: 20px;
}

.ud-widget .ud-widget-socials a:hover {
    color: var(--primary-color);
}

.ud-widget .ud-widget-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 35px;
    color: var(--white);
}

.ud-widget .ud-widget-links a {
    display: inline-block;
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 30px;
    color: #f3f4fe;
}

.ud-widget .ud-widget-links a:hover {
    color: var(--primary-color);
    padding-left: 10px;
}

.ud-widget .ud-widget-brands {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ud-widget .ud-widget-brands a {
    display: block;
    max-width: 120px;
    margin-bottom: 20px;
    margin-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .ud-widget .ud-widget-brands a {
        max-width: 100px;
    }
}

.ud-footer-bottom {
    padding: 35px 0;
    border-top: 1px solid rgba(136, 144, 164, 0.43);
}

.ud-footer-bottom .ud-footer-bottom-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 767px) {
    .ud-footer-bottom .ud-footer-bottom-left {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.ud-footer-bottom .ud-footer-bottom-left a {
    font-weight: normal;
    font-size: 15px;
    color: #f3f4fe;
    margin-right: 30px;
    display: inline-block;
}

@media (max-width: 767px) {
    .ud-footer-bottom .ud-footer-bottom-left a {
        margin: 0px 8px 8px;
    }
}

.ud-footer-bottom .ud-footer-bottom-left a:hover {
    color: var(--primary-color);
}

.ud-footer-bottom .ud-footer-bottom-right {
    text-align: right;
    font-weight: normal;
    font-size: 15px;
    color: #f3f4fe;
}

.ud-footer-bottom .ud-footer-bottom-right a {
    color: var(--primary-color);
}

.ud-footer-bottom .ud-footer-bottom-right a:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {
    .ud-footer-bottom .ud-footer-bottom-right {
        text-align: center;
        margin-top: 10px;
    }
}