.icons {
    position: absolute;
    top: 11px;
    left: 20px;
    font-size: 22px;
    z-index: 1;
}

.icons-select {
    position: absolute;
    top: 11px;
    left: 20px;
    font-size: 22px;
    z-index: 1;
}

.control-quejas {
    width: 100%;
    height: 50px;
    position: relative;
    margin-left: 20px;
    padding-left: 50px !important;
    background-color: white;
    border-radius: 25px !important;
    color: "black" !important;
    /* border-width: 1; */
}

.form-select-quejas {
    width: 100%;
    height: 50px;
    position: relative;
    margin-left: 20px;
    padding-left: 50px !important;
    background-color: white;
    border-radius: 25px    !important;
    color: "black" !important;
    /* border-width: 1; */
}

