body {

    /* overflow-y: auto; */
    /* position: relative; */
    /* width: 100%; */

    /* background-color: #F3F8FF !important; */
    /* background-image: linear-gradient(to top, #f9f9f9, #f8f8fa, #f7f8fc, #f5f8fe, #f3f8ff) !important; */
    /* background-color: red !important; */

    /* background-color: #f6f8fc !important; */
    
    /* background-color: #F5F7F8 !important; */
    /* background-color: #f3f4f6 !important; */

    /* background-color: #ecedee !important; */

    /* padding-left: 0; */
    /* Elimina el relleno a la izquierda */

    /* scrollbar-width: thin;
    scrollbar-color: #333 #fff;

    overflow-x: hidden; */


    /* Internet Explorer / Edge */

    background-color: #FBFBFB ;
}
