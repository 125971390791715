.linea-style-left {
    display: grid;
    background: red;
    width: 245px;
    height: 2px;
    /* margin: auto; */
    text-align: left;
    margin-top: 15px;
}

.flip-container {
    /* background-color: transparent; */
    width: 250px;
    height: 300px;
    margin-left: 15px;
    perspective: 1000px;
    border-radius: 30px;
    z-index: 2;
    padding: 5px;
    /* Asegura que las tarjetas estén por debajo del Navbar */
}

.card-flip {
    position: static;
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.flip-container:hover .card-flip {
    transform: rotateY(180deg);
    z-index: 2;
    /* Asegura que las tarjetas estén por debajo del Navbar */

}

.front,
.back {
    position: fixed;
    top: 0;
    /* z-index: -1; */
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
}

.front {
    background-color: rgb(44, 60, 148);
    color: #fff;
    border-radius: 30px;
}

.back {
    border-radius: 30px;
    background-color: #d42b29;
    color: white;
    transform: rotateY(180deg);
}


/* Principio_valores.css */
@media (max-width: 576px) {
    .mobile-full-width {
        width: 100% !important;
        max-width: 90%;
        margin: 0 auto;
    }
}
