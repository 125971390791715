.video-container {
    position: relative;
    width: 100%;
    height: 400px; /* Altura fija */
    overflow: hidden;
}

.myVideo {
    width: 100%;
    height: 350px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    mask-image: linear-gradient(to bottom, transparent, black 10%, black 90%, transparent);
    /* Esto da un efecto visual de degradado */
}

.footer-text {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 1; /* Coloca el texto sobre el video */
}

.imagen-cartas {
    /* background: -webkit-linear-gradient(red, #F3F8FF 50%); */
    background: linear-gradient(to bottom, transparent 0%, #F3F8FF 100%);
    height: 100px;
    width: 100%;
    margin-top: -5rem;
    position: relative;

    /* box-shadow: 0 4px 6px rgb(0, 0, 0, 0); */
}

.Nuestros-productos {
    /* z-index: 3 !important; */
    height: "45px";
    background-color: "red";
    border-radius: "15px 30px 50px 5px";
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 3;
}

.content {
    transition: transform .2s;
}

.content:hover {
    transform: scale(1.1);
    z-index: 9999;
}

.Title-style {
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-justify: auto;
    position: relative;
}

.a-style {
    color: black !important;

}

.a-style:hover {
    color: black !important;

}

.ver-style {
    color: red !important;
}

.ver-style:hover {
    color: black !important;
}

.linea-style {
    display: grid;
    background: red;
    width: 245px;
    height: 2px;
    text-align: left;
    margin-top: 15px;
}

.custom-swiper {
    margin-bottom: 20px;
    /* Ajusta este valor según tus necesidades */
}

.custom-swiper .swiper-pagination {
    margin-top: 10rem;
    /* Ajusta este valor según tus necesidades */
}

.mySwiperContent .swiper-slide-image {
    /* -webkit-mask-image: linear-gradient(black 80%, transparent); */
    /* mask-image: linear-gradient(black 80%, transparent); */
}

.fondoProductos {
    background-repeat: no-repeat;
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1001%26quot%3b)' fill='none'%3e%3cpath d='M880.64 635.58C1004.33 610.8 1033.33 312.07 1251.41 293.32 1469.49 274.57 1524.52 171.72 1622.18 170.12' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M245.67 601.28C386.25 587.77 474.1 292.66 717.59 290.78 961.08 288.9 953.55 360.78 1189.5 360.78 1425.46 360.78 1542.15 290.97 1661.42 290.78' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M291.99 597.29C460.29 553.16 506.96 100.82 769.11 90.61 1031.27 80.4 1007.67 160.61 1246.24 160.61 1484.8 160.61 1602.8 90.8 1723.36 90.61' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M561.39 643.29C659.09 632.24 616.3 427.78 882.68 416.44 1149.05 405.1 1354.69 190.25 1525.25 186.84' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M267.69 561.78C406.01 550.04 496.65 269.28 738.74 267.75 980.82 266.22 974.26 337.75 1209.78 337.75 1445.31 337.75 1561.78 267.94 1680.83 267.75' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1001'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e"); */
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1005%26quot%3b)' fill='none'%3e%3cpath d='M -377.4455325428195%2c197 C -281.45%2c222.4 -89.45%2c324.8 102.5544674571805%2c324 C 294.55%2c323.2 390.55%2c165.6 582.5544674571805%2c193 C 774.55%2c220.4 870.55%2c450.8 1062.5544674571804%2c461 C 1254.55%2c471.2 1467.07%2c252.6 1542.5544674571804%2c244 C 1618.04%2c235.4 1460.51%2c383.2 1440%2c418' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -349.4474442241437%2c189 C -253.45%2c216 -61.45%2c348.8 130.55255577585632%2c324 C 322.55%2c299.2 418.55%2c51.2 610.5525557758563%2c65 C 802.55%2c78.8 898.55%2c367.4 1090.5525557758563%2c393 C 1282.55%2c418.6 1500.66%2c209.4 1570.5525557758563%2c193 C 1640.44%2c176.6 1466.11%2c287.4 1440%2c311' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -9.285582299810585%2c145 C 86.71%2c211.8 278.71%2c454 470.7144177001894%2c479 C 662.71%2c504 758.71%2c273 950.7144177001894%2c270 C 1142.71%2c267 1238.71%2c497 1430.7144177001894%2c464 C 1622.71%2c431 1908.86%2c137.8 1910.7144177001894%2c105 C 1912.57%2c72.2 1534.14%2c261 1440%2c300' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -857.1664622495873%2c284 C -761.17%2c274.8 -569.17%2c199 -377.16646224958737%2c238 C -185.17%2c277 -89.17%2c477 102.83353775041263%2c479 C 294.83%2c481 390.83%2c285.6 582.8335377504127%2c248 C 774.83%2c210.4 891.4%2c312.2 1062.8335377504127%2c291 C 1234.27%2c269.8 1364.57%2c171.8 1440%2c142' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1005'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e"); */

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1013%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c247 C 57.6%2c207 172.8%2c54.4 288%2c47 C 403.2%2c39.6 460.8%2c205 576%2c210 C 691.2%2c215 748.8%2c59.6 864%2c72 C 979.2%2c84.4 1036.8%2c284.8 1152%2c272 C 1267.2%2c259.2 1382.4%2c60.8 1440%2c8L1440 560L0 560z' fill='rgba(246%2c 245%2c 245%2c 1)'%3e%3c/path%3e%3cpath d='M 0%2c342 C 96%2c360.4 288%2c418.8 480%2c434 C 672%2c449.2 768%2c398.6 960%2c418 C 1152%2c437.4 1344%2c508.4 1440%2c531L1440 560L0 560z' fill='rgba(242%2c 242%2c 242%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1013'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    /* mask-image: linear-gradient(black 90%, transparent); */
    /* filter:  blur(2px); */
}


.ud-hero {
    position: relative;
    height: 90vh;
    /* background: var(--primary-color); */
    /* background: #3056d3; */
    /* background: linear-gradient(to bottom, white 10%, #3056d3 90%); */
    background-image: url("../../img/Diseño sin título (11).png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    mask-image: linear-gradient(black 95%, transparent);
    /* filter: blur(2px); */
    /* Ajusta el nivel de desenfoque */
    /* padding-top: 180px; */
    padding-top: 30vh;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ud-hero {
        padding-top: 160px;
    }
}

@media (max-width: 767px) {
    .ud-hero {
        padding-top: 50px;
    }
}

.ud-hero-content {
    max-width: 780px;
    margin: auto;
    margin-bottom: 30px;
    /* Asegura que el contenido esté por encima de la imagen */

    position: relative;
    z-index: 1;
    /* Asegura que el contenido esté por encima de la imagen */

}

.ud-hero-content .ud-hero-title {
    color: var(--white);
    font-weight: 700;
    /* font-size: 45px; */
    font-size: 5em;
    line-height: 60px;
    text-align: center;
    margin-bottom: 30px;
    z-index: 3 !important;

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ud-hero-content .ud-hero-title {
        font-size: 38px;
        line-height: 50px;
    }
}

@media (max-width: 767px) {
    .ud-hero-content .ud-hero-title {
        font-size: 26px;
        line-height: 38px;
    }
}

.ud-hero-desc {
    font-weight: normal;
    font-size: 25px;
    line-height: 35px;
    text-align: left;
    /* color: var(--white); */
    color: #202021;
    opacity: 0.8;
    max-width: 600px;
    /* margin: auto; */
    margin-bottom: 30px;
    margin-top: 20px;
}

.ud-hero-content .ud-hero-desc {
    font-weight: normal;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    /* color: var(--white); */
    color: #202021;
    opacity: 0.8;
    max-width: 600px;
    margin: auto;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .ud-hero-content .ud-hero-desc {
        font-size: 20px;
        line-height: 30px;
    }
}

.ud-hero-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.ud-hero-buttons .ud-main-btn {
    margin: 8px;
}

@media (max-width: 767px) {
    .ud-hero-buttons .ud-main-btn {
        margin: 5px;
    }
}

@media (max-width: 767px) {
    .ud-hero-buttons .ud-main-btn {
        padding: 12px 16px;
    }
}

.ud-hero-buttons .ud-white-btn {
    background: var(--white);
    color: var(--heading-color);
}

.ud-hero-buttons .ud-white-btn:hover {
    -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    background: #fefefe;
    color: var(--primary-color);
}

.ud-hero-buttons .ud-link-btn:hover {
    color: var(--white);
    background: transparent;
    opacity: 0.5;
}

.ud-hero-brands-wrapper {
    text-align: center;
    margin-bottom: 40px;
}

.ud-hero-brands-wrapper img {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0.5;
    max-width: 250px;
}

.ud-hero-brands-wrapper img:hover {
    opacity: 1;
}

.ud-hero-image {
    max-width: 845px;
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 1;
    gap: 2em;

    display: flex;
    /* Cambiado para que gap funcione */
    justify-content: center;
    /* Opcional: centra las imágenes horizontalmente */
    gap: 5em;
    /* Espaciado entre las imágenes */
}


.ud-hero-image img {
    max-width: 100%;
    text-align: center;
}

.ud-hero-image .shape {
    position: absolute;
    z-index: -1;
}

.ud-hero-image .shape.shape-1 {
    bottom: 0;
    left: -30px;
}

.ud-hero-image .shape.shape-2 {
    top: -20px;
    right: -20px;
}



/* ===== Features CSS ===== */
.ud-features {
    /* padding-top: 120px; */
    padding-top: 80px;
    /* padding-bottom: 90px; */
}

@media (max-width: 767px) {
    .ud-features {
        padding-top: 80px;
        padding-bottom: 40px;
    }
}

.ud-section-title {
    max-width: 620px;
    margin-bottom: 70px;
}

.ud-section-title span {
    font-weight: 600;
    font-size: 18px;
    color: var(--primary-color);
    margin-bottom: 10px;
}

.ud-section-title h2 {
    font-weight: 700;
    font-size: 42px;
    line-height: 55px;
    text-transform: capitalize;
    color: var(--heading-color);
    margin-bottom: 20px;
}

.ud-section-title p {
    font-size: 20px;
    line-height: 30px;
}

@media (max-width: 767px) {
    .ud-section-title p {
        font-size: 16px;
        line-height: 26px;
    }
}

.ud-single-feature {
    margin-bottom: 40px;
}

.ud-single-feature:hover .ud-feature-icon::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.ud-single-feature .ud-feature-icon {
    width: 70px;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 14px;
    background: var(--primary-color);
    font-size: 32px;
    color: var(--white);
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: 40px;
}

.ud-single-feature .ud-feature-icon::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 14px;
    background: var(--primary-color);
    opacity: 0.2;
    z-index: -1;
    -webkit-transform: rotate(23deg);
    transform: rotate(23deg);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.ud-single-feature .ud-feature-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ud-single-feature .ud-feature-title {
        font-size: 18px;
        line-height: 26px;
    }
}

.ud-single-feature .ud-feature-desc {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
}

.ud-single-feature .ud-feature-link {
    font-weight: 500;
    color: var(--body-color);
}

.ud-single-feature .ud-feature-link:hover {
    color: var(--primary-color);
}

/* ===== About CSS ===== */
/* Contenedor principal de ud-about */
.ud-about {
    background: #f3f4fe;
    padding: 50px 0;
}

@media (max-width: 767px) {
    .ud-about {
        padding: 80px 0;
    }
}

.row {
    display: flex;
    align-items: stretch; /* Hace que las columnas tengan el mismo alto */
}

.ud-about-wrapper {
    /* background: var(--white); */
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px !important;
    min-height: 100%; /* Ocupa el 100% del alto del contenedor padre */
}

.ud-about-content-wrapper {
    padding: 20px;
    flex: 1; /* Hace que el contenido principal ocupe el espacio disponible */
}

@media (max-width: 767px) {
    .ud-about-content-wrapper {
        padding: 50px 30px;
    }
}

.ud-about-content {
    max-width: 500px;
}

/* Resto de estilos */

.ud-about-content .tag {
    font-weight: 500;
    font-size: 14px;
    color: var(--white);
    background: var(--primary-color);
    padding: 5px 20px;
    display: inline-block;
    margin-bottom: 20px;
}

.ud-about-content h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .ud-about-content h2 {
        font-size: 26px;
        line-height: 38px;
    }
}

.ud-about-content p {
    line-height: 28px;
    margin-bottom: 40px;
}

.ud-about-content p li {
    line-height: 24px;
    margin-left: 20px;
}

.table thead th {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
}

.table tbody td {
    font-size: 14px;
    line-height: 24px;
    padding: 10px;
}

@media (max-width: 767px) {
    .table thead th {
        font-size: 14px;
    }

    .table tbody td {
        font-size: 12px;
    }

    .ud-about-content-wrapper {
        padding: 30px;
    }

    .ud-about-content p {
        font-size: 14px;
    }

    .ud-about-content .tag {
        font-size: 12px;
    }
}

.ud-about-image img {
    max-width: 100%;
    height: auto;
}

.ud-about-content h6 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 1em;
}



/* ===== Testimonials CSS ===== */
.ud-testimonials {
    /* padding-top: 120px; */
    /* padding-bottom: 90px; */
}

@media (max-width: 767px) {
    .ud-testimonials {
        padding-top: 80px;
    }
}

.ud-single-testimonial {
    /* padding: 30px; */
    -webkit-box-shadow: 0px 60px 120px -20px #ebeffd;
    box-shadow: 0px 60px 120px -20px #ebeffd;
    background: var(--white);
    margin-bottom: 50px;
    min-height: 150px;
    /* Altura mínima fija */
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.ud-single-testimonial .ud-testimonial-ratings {
    margin-bottom: 10px;
}

.ud-single-testimonial .ud-testimonial-ratings i {
    color: #fbb040;
}

.ud-single-testimonial .ud-testimonial-content {
    margin-bottom: 25px;
}

.ud-single-testimonial .ud-testimonial-content p {
    font-size: 16px;
    line-height: 30px;
    color: #637381;
}

.ud-single-testimonial .ud-testimonial-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ud-single-testimonial .ud-testimonial-info .ud-testimonial-image {
    /* width: 50px; */
    /* height: 50px; */
    /* width: "250px";
    height: "250px"; */
    width: 150px;
    height: 150px;
    object-fit: cover;
    /* border-radius: 50%; */
    overflow: hidden;
    margin-right: 20px;
}

.ud-testimonial-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ud-testimonial-image img {
    width: auto;
    height: 200px;
    object-fit: cover;
    /* border-radius: 50%; */
    overflow: hidden;
}

.ud-single-testimonial .ud-testimonial-info .ud-testimonial-meta h4 {
    font-weight: 600;
    font-size: 14px;

    white-space: nowrap;
    /* Evita que el texto se vaya a una nueva línea */
    overflow: hidden;
    /* Oculta el texto que se desborda */
    text-overflow: ellipsis;
    /* Agrega los tres puntos (...) */

    max-width: 10px;
    /* Ajusta el ancho máximo según sea necesario */
}

.ud-testimonial-meta h4 {
    font-weight: 600;
    font-size: 14px;

    white-space: nowrap;
    /* Evita que el texto se vaya a una nueva línea */
    overflow: hidden;
    /* Oculta el texto que se desborda */
    text-overflow: ellipsis;
    /* Agrega los tres puntos (...) */

    /* max-width: 10px; */
    /* Ajusta el ancho máximo según sea necesario */
}

.ud-single-testimonial .ud-testimonial-info .ud-testimonial-meta p {
    font-size: 12px;
    color: #969696;
}

.ud-brands .ud-title {
    margin-bottom: 30px;
}

.ud-brands .ud-title h6 {
    font-weight: normal;
    font-size: 12px;
    color: var(--body-color);
    display: inline-block;
    position: relative;
}

.ud-brands .ud-title h6::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    background: #afb2b5;
    right: -40px;
    top: 50%;
}

.ud-brands .ud-brands-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -40px;
}

.ud-brands .ud-brands-logo .ud-single-logo {
    margin-right: 40px;
    margin-bottom: 20px;
    max-width: 140px;
}

.ud-brands .ud-brands-logo .ud-single-logo:hover img {
    -webkit-filter: none;
    filter: none;
}

.ud-brands .ud-brands-logo .ud-single-logo img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

/* ===== Team CSS ===== */
.ud-team {
    padding-top: 120px;
    padding-bottom: 80px;
}

@media (max-width: 767px) {
    .ud-team {
        padding-top: 80px;
        padding-bottom: 40px;
    }
}

.ud-single-team {
    margin-bottom: 40px;
}

.ud-single-team .ud-team-image-wrapper {
    width: 170px;
    height: 170px;
    position: relative;
    margin: 0px auto 25px;
}

.ud-single-team .ud-team-image {
    border-radius: 50%;
}

.ud-single-team .ud-team-image img {
    width: 100%;
    border-radius: 50%;
}

.ud-single-team .shape {
    position: absolute;
    z-index: -1;
}

.ud-single-team .shape.shape-1 {
    top: 0;
    left: 0;
}

.ud-single-team .shape.shape-2 {
    bottom: 0;
    right: 0;
}

.ud-single-team .ud-team-info {
    text-align: center;
    margin-bottom: 20px;
}

.ud-single-team .ud-team-info h5 {
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.ud-single-team .ud-team-info h6 {
    font-weight: 500;
    font-size: 12px;
    color: var(--body-color);
}

.ud-single-team .ud-team-socials {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.ud-single-team .ud-team-socials a {
    width: 32px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0px 10px;
    color: #cdced6;
}

.ud-single-team .ud-team-socials a:hover {
    color: var(--primary-color);
}





/* ===== FAQ CSS ===== */
.ud-faq {
    padding-top: 120px;
    padding-bottom: 90px;
    background: #f3f4fe;
    position: relative;
    z-index: 1;
}

@media (max-width: 767px) {
    .ud-faq {
        padding-top: 80px;
        padding-bottom: 50px;
    }
}

.ud-faq .shape {
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
}

.ud-single-faq {
    background: var(--white);
    border: 1px solid #f3f4fe;
    -webkit-box-shadow: 0px 20px 95px rgba(201, 203, 204, 0.3);
    box-shadow: 0px 20px 95px rgba(201, 203, 204, 0.3);
    border-radius: 10px;
    margin-bottom: 10px;
}

.ud-single-faq .ud-faq-btn {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    background: transparent;
    font-weight: 600;
    font-size: 18px;
    color: var(--heading-color);
    opacity: 0.88;
    padding: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
    .ud-single-faq .ud-faq-btn {
        padding: 15px;
    }
}

.ud-single-faq .ud-faq-btn.collapsed span.icon i {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.ud-single-faq .ud-faq-btn span.icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background: rgba(48, 86, 211, 0.06);
    border-radius: 10px;
    color: var(--primary-color);
    margin-right: 24px;
}

.ud-single-faq .ud-faq-btn span.icon i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 14px;
    font-weight: 700;
}

.ud-single-faq .ud-faq-body {
    /* padding: 0px 30px 40px 95px; */
    padding: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
    .ud-single-faq .ud-faq-body {
        padding: 0px 16px 20px 82px;
    }
}
